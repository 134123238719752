import * as React from "react";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import UserData from "./userData";
import UserDataRegistro from "./userDataRegistro"
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
// services
import { getUser } from "../services/persona";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { getUserQuota } from "../services/persona";
import InputLabel from "@mui/material/InputLabel";
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
import Autocomplete from "@mui/material/Autocomplete";
import { getJuzgados } from "../services/persona";



export default function Consult(props) {
  //const [displayUSerData, setDisplayUserData] = React.useState(false);
  
  const [displayUSerData, setDisplayUserData] = React.useState(false);
  const [displayLoading, setDisplayLoading] = React.useState(false);
  const [disableSearchButton, setDisableSearchButton] = React.useState(true);
  const [displayQuotaExeeded, setDisplayQuotaExeeded] = React.useState(false);
  const [displayAPIError, setDisplayAPIError] = React.useState(false);
  const [displayServerError, setDisplayServerError] = React.useState(false);
  const [displayNotFound, setDisplayNotFound] = React.useState(false);
  const [inputNumeroDocumento, setInputNumeroDocumento] = React.useState("");
  const [inputNumeroExpediente, setInputNumeroExpediente] = React.useState("");
  const [inputSexo, setInputSexo] = React.useState("mujer");
  const [juzgados, setJuzgados] = React.useState([]);
  const [selectedJuzgado, setSelectedJuzgado] = React.useState("");
  const [responseAvisoFallecimiento, setResponseAvisoFallecimiento] =
   
  React.useState("");
  // let userDataObj: UserData = {};
  let userDataObj = {};

  const [userData, setUserDataObj] = React.useState(userDataObj);
  // console .log('-Buenas--');

  // console.log(userData.calle);
    



  useEffect(() => {
    getJuzgados().then((r) => {
      setJuzgados(r["data"]["data"]);
    });
    // console.log('---')
    // console.log('in useeffect')
    // console.log(userData)

    // setDomicilio(assembleDomicilio(userData))

  }, []);

  useEffect(() => {
    if (
      inputNumeroDocumento.length < 8 ||
      selectedJuzgado === "" ||
      inputNumeroExpediente.length < 1
    ) {
      setDisableSearchButton(true);
    } else {
      setDisableSearchButton(false);
    }
  }, [inputNumeroDocumento, selectedJuzgado, inputNumeroExpediente]);

  const searchHandler = (userInput) => {
    setDisplayLoading(true);
      getUser(
        inputNumeroDocumento,
        inputSexo,
      inputNumeroExpediente,
      selectedJuzgado
    ).then((data) => {
      let status = data["data"]["status"];
      data = data.data.data;

      setDisplayLoading(false);
      // console.log("STATUSCODE");
      // console.log(status);
      if (status["code"] === "100") {
        setDisplayNotFound(true);
        setDisplayQuotaExeeded(false);
        
        setDisplayServerError(false);
        setDisplayAPIError(false);
      }
      if (status["code"] === "300") {
        // console.log("quota exeeded");
        setDisplayQuotaExeeded(true);
        setDisplayNotFound(false);
        setDisplayServerError(false);
        setDisplayAPIError(false);
      }
      if (status["code"] === "200") {
        data["domicilioFull"] = assembleDomicilio(data);
        data["edad"] = calcularEdad(data);
        data["fechaNacimientoFull"] = assembleFechaNacimiento(data);  
        setUserDataObj(data);
        setDisplayUserData(true);
        setDisplayNotFound(false);
        setDisplayServerError(false);
        setDisplayQuotaExeeded(false);
        setDisplayAPIError(false);
      }

      if (status["code"] === "581") {
        setDisplayAPIError(true);
        setDisplayQuotaExeeded(false);
        setDisplayNotFound(false);
        setDisplayServerError(false);
      }

      if (status["code"] === "587") {
        setDisplayServerError(true);
        setDisplayQuotaExeeded(false);
        setDisplayNotFound(false);

        setDisplayAPIError(false);
      }
      if (status["code"] === "425") {
        //jwt expired
        //document.location.reload(true)
        window.location.href = process.env.REACT_APP_URL_LOGOUT_RELOAD;
      }
    });
  };

  const resetHandler = () => {
    setDisplayQuotaExeeded(false);
    setDisplayNotFound(false);
    setDisplayUserData(false);
    setDisplayServerError(false);
    setInputNumeroDocumento("");
    setInputNumeroExpediente("");
    setSelectedJuzgado("");
    setInputSexo("mujer");
    setUserDataObj(userDataObj);
    let userName = localStorage.getItem("userName");
    getUserQuota(userName).then((r) => {
      props.setRestingQuota(r.data.data);
    });
  };


  const toChild = { userData };


  const handleChangeInputSexo = (event) => {
    setInputSexo(event.target.value);
    // console.log(event.target.value);
    setUserDataObj({...userData,sexo_added:event.target.value});
  };
  



  //funcion domicilio
  function assembleDomicilio(userData) {
    // console.log('-ASSEMBLEDOMICILIO--')
    // console.log(userData) 
   
    let outPut = "";
    if (userData.calle !== null) { outPut += "CALLE " + userData.calle + " "; }
    if (userData.numero !== null) { outPut += userData.numero + ", "; }
    if (userData.barrio !== null && userData.barrio !== "0") { outPut += "BARRIO " + userData.barrio  + " "; }
    if (userData.monoblock !== null) { outPut += "MONOBLOCK " + userData.monoblock+ " " ; }
    if (userData.piso !== null) { outPut += "PISO " + userData.piso + " ";}
    if (userData.departamento !== null) { outPut += "DEPARTAMENTO " + userData.departamento + " "}
    // console.log('-ASSEMBLEDOMICILIO--')
    // console.log(outPut);
    return outPut;
    
  }

  
  //calcular edad a partir de fecha de .fechaNacimiento y fecha actual  
  function calcularEdad(userData) {
    if (userData.mensaf === "FALLECIDO") {
      return "--";
    } else {
      let hoy = new Date();
      let cumpleanos = new Date(userData.fechaNacimiento);
      let edad = hoy.getFullYear() - cumpleanos.getFullYear();
      let m = hoy.getMonth() - cumpleanos.getMonth();
      if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
      }
      return edad;
    }
  }
  
  
   
  


// funcion para transformar en fecha DD MM AAA  y se muestra en el campo fecha de nacimiento.
function assembleFechaNacimiento(userData) 
{
  let fechaNacimientoFull=userData.fechaNacimiento 
  let outPut = "";
  if(fechaNacimientoFull!==null){outPut+= fechaNacimientoFull.substring(8,10) + "-";}
  if(fechaNacimientoFull!==null){outPut+= fechaNacimientoFull.substring(5,7) + "-";}
  if(fechaNacimientoFull!==null){outPut+= fechaNacimientoFull.substring(0,4) ;}
  return outPut 

} 



  

  
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom marginBottom="1em">
        Consultar Datos de Persona
      </Typography>

      {!displayUSerData ? (
        <Grid container>
          <Grid item xs={12} sm={12}>
            <TextField
              error={
                inputNumeroDocumento.length < 5 ||
                inputNumeroDocumento.length > 15 ||
                isNaN(inputNumeroDocumento) ||
                parseInt(inputNumeroDocumento) !==
                  parseFloat(inputNumeroDocumento) ||
                inputNumeroDocumento[inputNumeroDocumento.length - 1] === "." ||
                inputNumeroDocumento < 1
              }
              required
              id="firstName2"
              name="firstName"
              onChange={(e) => setInputNumeroDocumento(e.target.value)}
              value={inputNumeroDocumento||""}
              label="Número de Documento"
              fullWidth
              autoComplete="given-name"
              variant="standard"
            />
            
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              error={
                inputNumeroExpediente.length < 2 ||
                inputNumeroExpediente.length > 20
              }
              required
              id="firstName"
              name="firstName"
              onChange={(e) => setInputNumeroExpediente(e.target.value||"")}
              value={inputNumeroExpediente||""}
              label="Número de Expediente"
              fullWidth
              autoComplete="given-name"
              variant="standard"
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            style={{ paddingTop: "20px", paddingBottom: "20px" }}
          >
            <Autocomplete
              disablePortal
              disableClearable
              id="combo-box-demo"
              options={juzgados??[]}
              getOptionLabel={(option) => option.Descripcion}
              onChange={(event, newValue) => {
                setSelectedJuzgado(newValue);
              }}
              sx={{ width: 450 }}
              renderInput={(params) => (
                <TextField {...params} label="Juzgado/ Oficina" />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Sexo registrado al nacer
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                value={inputSexo??""}
                onChange={handleChangeInputSexo}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="mujer"
                  control={<Radio />}
                  label="Mujer"
                />
                <FormControlLabel
                  value="hombre"
                  control={<Radio />}
                  label="Hombre"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Box sx={{ width: "100%" }}>
            {displayLoading && <LinearProgress />}
          </Box>
        </Grid>
      ) : (
       <> 
       <UserData 
       data={toChild}
       sexo={inputSexo}
       documento={inputNumeroDocumento}
       domicilio={userData.domicilioFull}
       fechaNacimientoFull={userData.fechaNacimientoFull}
       edad={userData.edad}/>
       <UserDataRegistro/>
        </>
        
      )}

       


      {!displayUSerData && (
        <Grid item xs={12} sm={12} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            sx={{ mt: 3, ml: 1 }}
            onClick={searchHandler}
            disabled={displayLoading || disableSearchButton}
          >
            Consultar
          </Button>
        </Grid>
      )}

      {displayUSerData && (
        <Grid item xs={12} sm={12} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            sx={{ mt: 3, ml: 1 }}
            onClick={resetHandler}
          >
            Nueva Consulta
          </Button>
        </Grid>
      )}

      <Grid>
        {displayNotFound && (
          <Alert severity="warning">
            <AlertTitle>Aviso</AlertTitle>
            Persona no encontrada
          </Alert>
        )}

        {displayQuotaExeeded && (
          <Alert severity="warning">
            <AlertTitle>Aviso</AlertTitle>
            Cantidad de Consultas excedida
          </Alert>
        )}
        {displayAPIError && (
          <Alert severity="warning">
            <AlertTitle>Aviso</AlertTitle>
            RENAPER no responde
          </Alert>
        )}

        {displayServerError && (
          <Alert severity="warning">
            <AlertTitle>Aviso</AlertTitle>
            Error del Servidor
          </Alert>
        )}
      </Grid>
    </React.Fragment>
  );


  
  
}

