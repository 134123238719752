import React, { useState, useEffect } from "react";
import { Document, Page, Text, View, Image, StyleSheet, pdf, PDFDownloadLink } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  body: {
    color: "#333333",
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    backgroundColor: "#F8F8F8",
  },
  title: {
    fontSize: 18, 
    textAlign: "center",
    color: "#FFFFFF", 
    backgroundColor: "#6495ED", 
    padding: 10, 
    margin: 10,
    borderRadius: 5,
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 2, 
  },
  
  text: {
    margin: 10,
    fontSize: 10,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    padding: 1,
    marginBottom: 2,
    color: "#666666",
  },
  textbold: {
    margin: 10,
    fontSize: 10,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    padding: 1,
    marginBottom: 2,
    color: "#333333",
    fontWeight: "800",
  },
  header: { 
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "#A0A0A0",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "#A0A0A0",
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between", 
    marginBottom: 2,
    borderBottomWidth: 1,
    borderBottomColor: "#808080",
    backgroundColor: "#FFFFFF",
    textAlign: "justify",
    fontSize: 9,
    fontFamily: "Times-Roman",
    padding: 1,
  },
  column: {
    flex: 1,
    marginLeft: 1,
    marginRight: 1,
  },
  subtitle: {
    fontSize: 12,
    marginTop: 10,
    marginBottom: 5,
    color: "#FFFFFF", 
    backgroundColor: "#6495ED", 
    padding: 5,
    borderRadius: 5,
    textAlign: "center",
    textShadowColor: "#000000", 
    textShadowOffset: { width: 1, height: 1 }, 
    textShadowRadius: 2, 
  },
  image: {
    marginVertical: 20,
    marginHorizontal: 100,
    width: 100,
    height: 100,
    borderRadius: 50,
    textAlign: "center",
    textShadowColor: "#000000",
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 2,

  },


  
});


const DocuPDF = ({ jsonDatos }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    setData(jsonDatos);
    // console.log("desde docuPDF");
    // console.log(jsonDatos);
  }, [jsonDatos]);



  

  const pdfDoc = (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.title}>Datos Personas Poder Judicial </Text>
        <Text style={styles.subtitle}>RENAPER</Text>
        
        <View style={styles.container}>
        <View style={styles.column}>
          <Text style={styles.text}><Image style={styles.image} src={jsonDatos.toChild?.data?.userData?.foto ?? "sin datos"} /></Text>
        </View>
        <View style={styles.column}>
            <Text style={styles.text}>Nombres:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.nombres ?? "sin datos"}</Text>
            <Text style={styles.text}>Documento:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.documento ?? "sin datos"}</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.text}>Apellido:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.apellido ?? "sin datos"}</Text>
            <Text style={styles.text}>Fecha de Nacimiento:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.fechaNacimientoFull ?? "sin datos"}</Text>
          </View>
        </View>
       
        {/* <View style={styles.container}>
          <View style={styles.column}>
            <Text style={styles.text}>Nombres:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.nombres ?? "sin datos"}</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.text}>Apellido:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.apellido ?? "sin datos"}</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.text}>Documento:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.documento ?? "sin datos"}</Text>
          </View>
        </View> */}
        <View style={styles.container}>
        <View style={styles.column}>
      
        <Text style={styles.text}> </Text>
          </View>

          <View style={styles.column}>
          <Text style={styles.text}>Edad:</Text>
            <Text style={styles.textbold}>
              {jsonDatos.toChild?.data?.userData?.edad === "--"
                ? "Persona Fallecida"
                : jsonDatos.toChild?.data?.userData?.edad ?? "sin datos"}
            </Text>
        </View>

          <View style={styles.column}>
            <Text style={styles.text}>Sexo:</Text>
            <Text style={styles.textbold}>{(jsonDatos.toChild?.sexo ?? "sin datos").toUpperCase()}</Text>
          </View>
        </View>


        <View style={styles.container}>
          <View style={styles.column}>
            <Text style={styles.text}>Domicilio:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.domicilioFull ?? "sin datos"}</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.text}>Ciudad:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.ciudad ?? "sin datos"}</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.text}>Código Postal:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.cpostal ?? "sin datos"}</Text>
          </View>
        </View>
        
        <View style={styles.container}>
          <View style={styles.column}>
            <Text style={styles.text}>Municipio:</Text>
            <Text style={styles.text}>{jsonDatos.toChild?.data?.userData?.municipio ?? "sin datos"}</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.text}>Provincia:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.provincia ?? "sin datos"}</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.text}>País:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.pais ?? "sin datos"}</Text>
          </View>
        
        </View>
        <View style={styles.container}>
          <View style={styles.column}>
            <Text style={styles.text}>Ejemplar Documento:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.EJEMPLAR ?? "sin datos"}</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.text}>Fecha de Emisión:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.EMISION ?? "sin datos"}</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.text}>Fecha de Vencimiento:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.VENCIMIENTO ?? "sin datos"}</Text>
          </View>
        </View>

        <Text style={styles.header} fixed>
        
        Constancia Emitida: {new Date().toLocaleString()}  
       </Text>

     
        <Text
          style={{
            position: "absolute",
            fontSize: 12,
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: "center",
            color: "grey",
          }}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );

  return (
    <div>
      <PDFDownloadLink document={pdfDoc}   fileName={`Constancia ${jsonDatos?.toChild?.data?.userData?.apellido ?? "sin datos"}.pdf`}
>
        {({ blob, url, loading, error }) =>
          loading ? "Generando documento..." : <button style={{ backgroundColor: "#1976d2", color: "white", padding: 6,margin: 24, borderRadius: 5, border: "none",radius:4 }}>Descargar Constancia</button>
        }
      </PDFDownloadLink>
    </div>
  );
};

export default DocuPDF;
